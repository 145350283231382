import axios from "axios";

const baseUrl = 'https://safepay.rummykila.in/recharge/v1/api/';
const merchantId = '458409875618136214';
const KEY = '17b6743f0db4c3123d443dfe4c4786e0';

const getPayUrl = (params) => {
  return new Promise(resolve => {
    // Import the required libraries
    const CryptoJS = require("crypto-js");
    // const axios = require("axios");

    // Set the request URL and headers
    // const url = "https://fpay1.rrummy.com/payin/api/pay";
    const url = `${baseUrl}s2sPay`;
    const headers = {
      merchantId,
      "Content-Type": "application/json",
    };

    // Sort the parameters by parameter name from smallest to largest
    const sortedParams = {};
    Object.keys(params)
      .sort()
      .forEach((key) => {
        sortedParams[key] = params[key];
      });

    // Stitch the original string used for endorsement
    let stringToSign = Object.keys(sortedParams)
      .map((key) => `${key}=${sortedParams[key]}`)
      .join("&");
    stringToSign += `&key=${KEY}`;

    // Generate the MD5 signature
    const signature = CryptoJS.MD5(stringToSign).toString();
    // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

    // Add the signature to the headers
    headers["signature"] = signature;

    // Send the Axios request
    axios
      .post(url, params, {
        headers: headers
      })
      .then((response) => {
        console.log(response.data);
        if (response.data.code !== "200") {
          alert(response.data.msg || "payment error!");
          resolve(null);
          return;
        }
        resolve(response.data.data)
      })
      .catch((error) => {
        resolve(null);
      });
  })
};

const payAPI = (params) => {
  // Import the required libraries
  const CryptoJS = require("crypto-js");
  // const axios = require("axios");

  // Set the request URL and headers
  // const url = "https://fpay1.rrummy.com/payin/api/pay";
  const url = `${baseUrl}s2sPay`;
  const headers = {
    merchantId,
    "Content-Type": "application/json",
  };

  // Set the request parameters
  // const params = {
  //   id: "",
  //   paymentMode: "UPI",
  //   vpa: "8637258307@paytm",
  //   targetApp: "PAYTM",
  //   cardNo: "4111111111111111",
  //   cardHolderName: "John Doe",
  //   cardExpiryMM: "12",
  //   cardExpiryYY: "22",
  //   cardCVV: "123",
  //   bankCode: "BANK1"
  // };

  // Sort the parameters by parameter name from smallest to largest
  const sortedParams = {};
  Object.keys(params)
    .sort()
    .forEach((key) => {
      sortedParams[key] = params[key];
    });

  // Stitch the original string used for endorsement
  let stringToSign = Object.keys(sortedParams)
    .map((key) => `${key}=${sortedParams[key]}`)
    .join("&");
  stringToSign += `&key=${KEY}`;

  // Generate the MD5 signature
  const signature = CryptoJS.MD5(stringToSign).toString();
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  // Add the signature to the headers
  headers["signature"] = signature;

  // Send the Axios request
  axios
    .post(url, params, {
      headers: headers
    })
    .then((response) => {
      console.log(response.data);
      if (response.data.code !== "200") {
        alert(response.data.msg || "payment error!");
        return;
      }
      var paymentLink = '';
      if (response.data.data.paymentLink) {
        paymentLink = response.data.data.paymentLink;
      } else {
        if (params.targetApp === "PHONEPE") {
          paymentLink = response.data.data.phonepePaymentLink;
        } else if (params.targetApp === "GPAY") {
          paymentLink = response.data.data.gpayPaymentLink;
        } else if (params.targetApp === "PAYTM") {
          paymentLink = response.data.data.paytmPaymentLink;
        } else if (params.targetApp === "BHIM") {
          paymentLink = response.data.data.bhimPaymentLink;
        }
      }
      if (paymentLink !== "") {
        // 储存支付方式
        localStorage.setItem('targetApp', JSON.stringify({
          [params.id]: params.targetApp
        }));
        window.location.href = paymentLink;
      } else {
        alert("payment error!");
      }
    })
    .catch((error) => {
      console.error(error);
      console.log(params.targetApp)
    });
};

const pagePayAPI = (params) => {
  // Import the required libraries
  const CryptoJS = require("crypto-js");
  // const axios = require("axios");

  // Set the request URL and headers
  const url = `${baseUrl}pagePay`;
  const headers = {
    merchantId,
    "Content-Type": "application/json",
  };

  // Sort the parameters by parameter name from smallest to largest
  const sortedParams = {};
  Object.keys(params)
    .sort()
    .forEach((key) => {
      sortedParams[key] = params[key];
    });

  // Stitch the original string used for endorsement
  let stringToSign = Object.keys(sortedParams)
    .map((key) => `${key}=${sortedParams[key]}`)
    .join("&");
  stringToSign += `&key=${KEY}`;

  // Generate the MD5 signature
  const signature = CryptoJS.MD5(stringToSign).toString();
  // axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

  // Add the signature to the headers
  headers["signature"] = signature;

  // Send the Axios request
  axios
    .post(url, params, {
      headers: headers
    })
    .then((response) => {
      console.log(response.data);
      // alert(JSON.stringify(response.data))
      if (response.data.code !== "200") {
        alert(response.data.msg || "payment error!");
        return;
      }
      const resData = response.data.data;
      let data;
      switch (resData.thirdType){
        case 'DECENT':
          data = resData.decentData;
          break;
        default:
          data = resData.phonepeData || resData.oepayData
            || resData.beepayData || resData.cashfreeData
            || resData.airpayData || resData.digibizpayData;
          break;
      }
      
      var paymentLink = data?.paymentLink;
      if (paymentLink !== "") {
        window.location.href = `https://prakruthi.shop/redirect.html?link=${encodeURIComponent(paymentLink)}`;
      } else {
        alert("payment error!");
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

const queryStatusAPI = (id) => {
  return new Promise((resolve, reject) => {
    // Import the required libraries
    const CryptoJS = require("crypto-js");
    // const axios = require("axios");
    
    // Set the request URL and headers
    const url = `${baseUrl}queryTxnStatus`;
    const headers = {
      merchantId,
      "Content-Type": "application/json",
    };
    
    // Set the request parameters
    const params = {
      orderId: id
    };
    
    // Sort the parameters by parameter name from smallest to largest
    const sortedParams = {};
    Object.keys(params)
      .sort()
      .forEach((key) => {
        sortedParams[key] = params[key];
      });
    
    // Stitch the original string used for endorsement
    let stringToSign = Object.keys(sortedParams)
      .map((key) => `${key}=${sortedParams[key]}`)
      .join("&");
    stringToSign += `&key=${KEY}`;
    
    // Generate the MD5 signature
    const signature = CryptoJS.MD5(stringToSign).toString();
    
    // Add the signature to the headers
    headers["signature"] = signature;
    
    // Send the Axios request
    axios
      .post(url, params, {
        headers: headers
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject()
      });
  })
};


const setUtrNoAPI = (params) => {
  return new Promise((resolve, reject) => {
    // Import the required libraries
    const CryptoJS = require("crypto-js");
    // const axios = require("axios");
    
    // Set the request URL and headers
    const url = `${baseUrl}setUtrNo`;
    const headers = {
      merchantId,
      "Content-Type": "application/json",
    };
    
    // Sort the parameters by parameter name from smallest to largest
    const sortedParams = {};
    Object.keys(params)
      .sort()
      .forEach((key) => {
        sortedParams[key] = params[key];
      });
    
    // Stitch the original string used for endorsement
    let stringToSign = Object.keys(sortedParams)
      .map((key) => `${key}=${sortedParams[key]}`)
      .join("&");
    stringToSign += `&key=${KEY}`;
    
    // Generate the MD5 signature
    const signature = CryptoJS.MD5(stringToSign).toString();
    
    // Add the signature to the headers
    headers["signature"] = signature;
    
    // Send the Axios request
    axios
      .post(url, params, {
        headers: headers
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        reject()
      });
  })
};

export {
  getPayUrl,
  payAPI,
  pagePayAPI,
  queryStatusAPI,
  setUtrNoAPI
};